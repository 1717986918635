<template>
  <div>
    <h2 class="content-block">Delivery data</h2>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="false"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
    >
      <DxFilterRow :visible="false"/>
      <DxHeaderFilter :visible="false"/>

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
              ref="selectBoxOptionsTourComponent"
              label="Current year"
              width="180"
              labelMode="floating"
              placeholder="Wähle ein Jahr"
              valueExpr="year"
              :readOnly="false"
              value=""
              :data-source="tourSelectBoxDataSource"
              display-Expr="year"
              @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>
        <DxItem location="before">
          <DxSelectBox
              ref="chooseSupplierSelectBox"
              label="Supplier"
              width="180"
              labelMode="floating"
              placeholder="Choose a supplier"
              :readOnly="isSupplierSelectBoxReadOnly"
              :value="userStore.getSupplierByUser[0]"
              :data-source="chooseSupplierSelectBoxDataSource"
              @value-changed="orderDataGridInstance.refresh()"
              display-Expr="name"
          />
        </DxItem>
        <DxItem name="addRowButton" location="after"/>
        <DxItem name="saveButton" location="after"/>
        <DxItem name="revertButton" location="after"/>
      </DxToolbar>

      <DxEditing
          mode="batch"
          :allow-adding="true"
          :allow-deleting="true"
          :allow-updating="true"
      />

      <dx-paging :page-size="10"/>
      <dx-pager :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="false"/>

      <dx-column
          dataField="id"
          caption="Id"
          data-type="string"
          :allow-editing="false"
          :visible="false"
      />

      <dx-column
          dataField="supplier_id"
          caption="Supplier_id"
          data-type="string"
          :allow-editing="false"
          :visible="false"
      />

      <dx-column
          data-field="invoice_no"
          caption="Invoice no."
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="contract_no"
          caption="Contract no."
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="item"
          caption="Item"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="width_size"
          caption="Width / Size"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="shipped_quantity"
          caption="Shipped quantity"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="total_net_weight"
          caption="Total weight"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_cotton_conventional"
          caption="Cotton Conventional"
          data-type="string"
          :minWidth="10000"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_cotton_fairtrade_ocs"
          caption="Cotton Fairtrade OCS"
          data-type="string"
          :allow-editing="true"
          :allow-updating="true"
      />

      <dx-column
          data-field="net_weight_cotton_fairtrade_gots"
          caption="Cotton Fairtrade GOTS"
          data-type="string"
          :allow-editing="true"
          :allow-updating="true"
      />

      <dx-column
          data-field="net_weight_cotton_gots"
          caption="Cotton GOTS"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_cotton_cmia"
          caption="Cotton CMIA"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_polyester_conventional"
          caption="Polyester Conventional"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_polyester_rcs"
          caption="Polyester RCS"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_polyester_grs"
          caption="Polyester GRS"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="net_weight_tencel"
          caption="Tencel"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column v-if="currentSelectedYear >= 2024"
          data-field="net_weight_linen"
          caption="Linen"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          v-if="currentSelectedYear <= 2023"
          data-field="net_weight_refibra"
          caption="Refibra"
          data-type="string"
          :allow-editing="true"
      />
    </DxDataGrid>
  </div>


</template>

<script>
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxToolbar,
  DxItem,
  DxHeaderFilter,
  DxEditing,
} from "devextreme-vue/data-grid";
import {useSupplierDataStore} from "@/stores/SupplierDataStore";
import {useSupplierStore} from "@/stores/SupplierStore";
import {useUserStore} from "@/stores/UserStore";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxSelectBox,
    DxItem,
    DxColumn,
    DxToolbar,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  setup() {

    const supplierDataStore = useSupplierDataStore();
    const supplierStore = useSupplierStore();
    const userStore = useUserStore();

    return {supplierDataStore, supplierStore, userStore};
  },

  async mounted() {
    /**
     * Frage die Daten für die UI Komponenten an
     */

    this.orderDataGridInstance.beginCustomLoading();

    await this.userStore.getUsers();
    await this.supplierStore.getSuppliers();

    this.yearSelectBox.option("value", new Date().getFullYear())

    this.orderDataGridInstance.endCustomLoading();
    this.orderDataGridInstance.option("loadPanel", {enabled: false});
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    let currentSelectedYear = this.supplierDataStore.availableYears[0]

    const orderGridDataSource = new DataSource({
      key: "id",
      load: async () => {

        await this.supplierDataStore.getSupplierDataBySupplierIdAndYear(this.supplierSelectBox.option("value").id, this.yearSelectBox.option("value"));
        return this.supplierDataStore.supplierData;
      },

      update: async (key, values) => {
        await this.supplierDataStore.updateSingleSupplierData(key, values);
      },

      insert: async (values) => {
        values.supplier_id = this.supplierSelectBox.option("value").id;
        values.year = this.yearSelectBox.option("value");
        await this.supplierDataStore.createSingleSupplierData(values);

      },

      remove: async (key) => {
        await this.supplierDataStore.deleteSingleSupplierData(key);
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "year",
      load: () => {
        const currentYear = new Date().getFullYear();
        const years = [];

        // Schleife von 3 Jahre in der Vergangenheit bis 1 Jahr in die Zukunft
        for (let i = currentYear - 3; i <= currentYear + 1; i++) {
          years.push({ year: i });
        }

        return years;
      },
      byKey: (key) => {
        return { year: key };
      },
    });

    const chooseSupplierSelectBoxDataSource = new DataSource({
      key: "id",
      load: () => {
        return this.userStore.getSupplierByUser

      },
      byKey: () => {
        return this.userStore.getSupplierByUser
      },
    });


    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {

      currentSelectedYear,

      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      chooseSupplierSelectBoxDataSource,
      orderGridDataSource,
      tourSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },
  methods: {

    async selectBoxTourValueChangedEvent(item) {
      this.currentSelectedYear = item.value;
      await this.orderDataGridInstance.refresh();
    }

    /**
     * Get User Data
     */

    /**
     * Filter Grid Functions
     * */


    /**
     * Zell Funktionen der Grid Spalten
     */


  },
  computed: {

    isSupplierSelectBoxReadOnly() {
      if (this.userStore.getSupplierByUser.length <= 1) {
        return true;
      } else {
        return false;
      }
    },

    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    yearSelectBox: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    supplierSelectBox: function () {
      return this.$refs.chooseSupplierSelectBox.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}


.dx-adaptive-item-text {
  background-color: #EAEAEA;
}
</style>
