import {useUserStore} from "@/stores/UserStore";
import {useSupplierStore} from "@/stores/SupplierStore";
import {useFinishedGoodsConsumptionStore} from "@/stores/finishedGoodsConsumptionStore";

export default {
  loggedIn() {
    const userStore = useUserStore();
    return userStore.user.token != null;
  },

  async logIn(email: string, password: string) {

    const userStore = useUserStore();
    try {
      await userStore.authenticateUser(email, password);
    } catch (e: any){
      return {
        isOk: false,
        message: "Fehler: " + e,
      };
    }

    return {
      isOk: true,
      data: null,
    };
  },

  async logOut() {
    const userStore = useUserStore();
    const supplierStore = useSupplierStore();
    const finishedGoodsConsumptionStore = useFinishedGoodsConsumptionStore();
    finishedGoodsConsumptionStore.$reset()
    userStore.$reset()
    supplierStore.$reset()
    location.reload();
  },
};
