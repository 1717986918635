import {defineStore} from "pinia";

export const useApplicationStore = defineStore('ApplicationStore', {

    state: () => {

        //const PROD_API_URL: string = 'https://backend.materialconsumption.dibella.de/api/';
        const PROD_API_URL: string = 'https://dibella-backend-prod.jg-agency.de/api/';

        const DEV_API_URL: string = 'http://localhost:8090/api/'

        return {
            PROD_API_URL,
            DEV_API_URL,
        };
    },

    actions: {},

    getters: {
        getApiUrl(): string {
            if (!window.location.href.includes("localhost")) {
                return this.PROD_API_URL;
            } else {
                return this.DEV_API_URL;
            }
        },
    },
});
